<template>
    <div>
        <el-form
            :model="form"
            label-width="120px"
            size="small"
            v-loading.fullscreen.lock="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中，请稍后......"
            element-loading-background="rgba(0, 0, 0, 0.7)"
        >
            <el-form-item label="联系电话(售前)">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="联系电话(售后)">
                <el-input v-model="form.after_sales_phone"></el-input>
            </el-form-item>
            <el-form-item label="e-mail">
                <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="QQ">
                <el-input v-model="form.qq"></el-input>
            </el-form-item>
            <el-form-item label="公司地址">
                <el-input v-model="form.company_address"></el-input>
            </el-form-item>
            <el-form-item label="页脚左一">
                <el-input v-model="form.one"></el-input>
            </el-form-item>
            <el-form-item label="页脚左一(链接)">
                <el-input v-model="form.one_url"></el-input>
            </el-form-item>
            <el-form-item label="页脚左二">
                <el-input v-model="form.two"></el-input>
            </el-form-item>
            <el-form-item label="页脚左二(链接)">
                <el-input v-model="form.two_url"></el-input>
            </el-form-item>
            <el-form-item label="页脚左三">
                <el-input v-model="form.three"></el-input>
            </el-form-item>
            <el-form-item label="页脚左三(链接)">
                <el-input v-model="form.three_url"></el-input>
            </el-form-item>
            <el-form-item label="页脚左四">
                <el-input v-model="form.four"></el-input>
            </el-form-item>
            <el-form-item label="页脚左四(链接)">
                <el-input v-model="form.four_url"></el-input>
            </el-form-item>
            <el-form-item label="页脚左五">
                <el-input v-model="form.five"></el-input>
            </el-form-item>
            <el-form-item label="页脚左五(链接)">
                <el-input v-model="form.five_url"></el-input>
            </el-form-item>
            <el-form-item label="域名">
                <el-input v-model="form.wylink"></el-input>
            </el-form-item>
            <el-form-item label="域名(链接)">
                <el-input v-model="form.wylink_link"></el-input>
            </el-form-item>
            <el-form-item label="隐私政策">
                <el-input v-model="form.privacy_policy"></el-input>
            </el-form-item>
            <el-form-item label="隐私政策(链接)">
                <el-input v-model="form.privacy_policy_link"></el-input>
            </el-form-item>
            <el-form-item label="介绍">
                <el-input v-model="form.introduce"></el-input>
            </el-form-item>
            <el-form-item label="公告">
                <el-input v-model="form.notice"></el-input>
            </el-form-item>
            <el-form-item label="年份">
                <el-input-number v-model="form.year" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="节日">
                <el-select v-model="form.festival" placeholder="请选择" clearable>
                    <el-option label="春节" value="春节"> </el-option>
                    <el-option label="下雪" value="下雪"> </el-option>
                    <el-option label="红包雨" value="红包雨"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="F12/右键行为">
                <el-switch
                    class="sw"
                    v-model="form.fonetwo"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="Ctrl+s行为">
                <el-switch
                    class="sw"
                    v-model="form.ctrls"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="拖拽状态">
                <el-switch
                    class="sw"
                    v-model="form.drag_status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="公告状态">
                <el-switch
                    class="sw"
                    v-model="form.notice_status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="默哀状态">
                <el-switch
                    class="sw"
                    v-model="form.sorrow_status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                    @change="
                        val => {
                            val ? '' : (form.sorrow_time = '');
                        }
                    "
                ></el-switch>
            </el-form-item>
            <el-form-item label="默哀结束时间" v-show="form.sorrow_status">
                <el-date-picker
                    v-model="form.sorrow_time"
                    align="right"
                    type="date"
                    placeholder="会在选择的时间的凌晨零点关闭默哀状态"
                    :picker-options="pickerOptions"
                    class="picker"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="网站状态">
                <el-switch
                    class="sw"
                    v-model="form.website_status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="水印状态">
                <el-switch
                    class="sw"
                    v-model="form.watermark"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="开启"
                    inactive-text="关闭"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="水印图">
                <upImg :src="form.watermark_image" @removeSrc="form.watermark_image = ''" ref="watermark_image"></upImg>
            </el-form-item>
            <el-form-item label="logo">
                <upImg :src="form.logo" @removeSrc="form.logo = ''" ref="logo"></upImg>
            </el-form-item>
            <el-form-item label="公众号码">
                <upImg :src="form.public_phone" @removeSrc="form.public_phone = ''" ref="public_phone"></upImg>
            </el-form-item>
            <!-- <el-form-item label="威有商城码">
                <upImg :src="form.shop_qrcode" @removeSrc="form.shop_qrcode = ''" ref="shop_qrcode"></upImg>
            </el-form-item>
            <el-form-item label="手机版码">
                <upImg :src="form.handset_qrcode" @removeSrc="form.handset_qrcode = ''" ref="handset_qrcode"></upImg>
            </el-form-item> -->
            <el-form-item label="客服二维码(微信)">
                <upImg :src="form.wechat_visitor" @removeSrc="form.wechat_visitor = ''" ref="wechat_visitor"></upImg>
            </el-form-item>
            <el-form-item label="客服(qq)">
                <div class="kefu-list">
                    <div class="qq" v-for="e in kefu" :key="e">
                        {{ e }} <i class="el-icon-circle-close" @click="delKefu(e)"></i>
                    </div>
                    <el-input v-model.trim="value" placeholder="输入qq号新增客服" @keydown.native.enter="addKefu"></el-input>
                </div>
            </el-form-item>
        </el-form>
        <!-- 悬浮按钮 -->
        <!-- <div class="page_fab">
            <button style="color: #000" @click="qr">确认</button>
            <button style="background-color: #000" @click="load">重新加载</button>
        </div> -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="qr"></VyIcon>
            <VyIcon name="重新加载" scale="4" @click.native="load"></VyIcon>
        </div>
    </div>
</template>

<script>
import upImg from "../../customs/uploadImg.vue";
import axios from "axios";
import qs from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { upImg, VyIcon },
    data() {
        return {
            form: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            loading: false,
            // 新增客服的输入框值
            value: "",
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        // 确认
        async qr() {
            let f = { ...this.form };
            if (f.sorrow_status && f.sorrow_time == "") return this.$message.error("请选择默哀状态结束时间！");
            // ----------------
            f.sorrow_time = this.timeSet(f.sorrow_time);
            for (const k in this.$refs) {
                f[k] = this.$refs[k].url;
            }
            // ----------------
            let { data } = await axios.put("/api/website/update", qs.stringify(f));
            if (data.code != 200) return this.$message.error(data.msg);
            this.$message.success(data.msg);
            this.load();
        },
        // 加载数据函数
        async load() {
            this.loading = true;
            let { data } = await axios.get("/api/website/edit");
            this.form = { ...data };
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
        // 处理时间
        timeSet(dateObj, num) {
            if (typeof dateObj === "string") return dateObj;
            if (num === 24) {
                let year = dateObj.getFullYear();
                let month = dateObj.getMonth() + 1;
                let day = dateObj.getDate();
                let hours = dateObj.getHours();
                let minutes = dateObj.getMinutes();
                let seconds = dateObj.getSeconds();
                month = month < 10 ? "0" + month : month;
                day = day < 10 ? "0" + day : day;
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            }
            return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
        },
        // 新增客服
        addKefu() {
            if (this.value == "") return this.$message.error("输入框为空！");
            if (isNaN(Number(this.value))) return this.$message.error("添加qq号请保证只包含数字且中间无空格！");
            if (this.kefu.findIndex(e => e == this.value) >= 0) return this.$message.error("存在相同qq号！");
            let arr = [...this.kefu];
            arr.push(this.value);
            this.kefu = arr;
            this.value = "";
        },
        // 删除客服
        delKefu(str) {
            let arr = this.kefu.filter(e => e !== str);
            this.kefu = arr;
        },
    },
    computed: {
        // 客服列表
        kefu: {
            get() {
                return !this.form.visitor ? [] : this.form.visitor.split(",");
            },
            set(val) {
                this.form.visitor = val.join(",");
            },
        },
    },
};
</script>

<style lang="less" scoped>
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
.picker {
    width: 300px;
    /deep/.el-input__inner {
        text-align: center;
    }
}

.kefu-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    & > div {
        display: inline-block;
    }
    .qq {
        white-space: nowrap;
        height: 30px;
        padding: 0px 10px;
        border-radius: 10px;
        border: 1px solid #7781f1;
        color: #7781f1;
        margin-right: 10px;
        margin-bottom: 5px;
        i {
            cursor: pointer;
        }
    }
    /deep/input {
        width: 150px;
    }
}
</style>
